<template>
    <v-container fluid>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/veiculos/marca" />
        </v-col>
      </v-row>
  
      <marca-veiculo-campos
        :carregando-salvar="carregandoSalvar"
        :carregando-buscar="carregandoBuscar"
        :validacao-formulario="validacaoFormulario"
        :marca-veiculo="marcaVeiculo"
        @salvar="salvar"
      />
    </v-container>
  </template>
  
  <script>
      import marcas from '@/api/marcas'
      import _ from 'lodash'
  
    export default {
      components: {
        MarcaVeiculoCampos: () => import('./MarcaVeiculoCampos'),
      },
  
      watch: { 

      marcaVeiculo: {
        immediate: true,
        handler (val) {
          if (_.isEmpty(val)) return false

          this.marcaNova = val
        },
      },
    },
      data () {
        return {
          carregandoSalvar: false,
          carregandoBuscar: false,
          validacaoFormulario: {},
          marcaVeiculo: {},
        }
      },
  
      mounted () {
        this.buscar()
      },
  
      methods: {
        async buscar () {
          try {
            this.carregandoBuscar = true
  
            const resposta = await marcas.buscar(this.$route.params.id)
  
            this.marcaVeiculo = resposta.data 
          } catch (e) {
            this.$router.push('/veiculos/marca', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
          } finally {
            this.carregandoBuscar = false
          }
        },
  
        async salvar (marca) {
          this.carregandoSalvar = true
  
          try {

            let dados = {
              id:marca.id, 
              descricao: marca.descricao
            }
            await marcas.atualizar(marca.id, dados)
  
            this.carregandoSalvar = false
  
            this.$router.push('/veiculos/marca', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
          
        } catch (e) {
            if (e.response.status === 422) {
              this.validacaoFormulario = this.$erroApi.validacao(e)
              return
            }
  
            this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          } finally {
            this.carregandoSalvar = false
          }
        },
      },
  
    }
  </script>
  